<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-row>
      <b-col
        align="right"
      >
        <b-button
          variant="outline-primary"
          class="mb-1 ml-2"
          @click="showAddForm = true"
        >
          Add Buyer Return
        </b-button>

      </b-col>
    </b-row>
    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayDetails(data.item.id)"
        />
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at ? formatedDate(data.item.created_at) : '-' }}
      </template>
      <template #cell(received_date)="data">
        {{ data.item.received_date ? formatedDate(data.item.received_date) : '-' }}
      </template>
      <template #cell(returned_date)="data">
        {{ data.item.returned_date ? formatedDate(data.item.returned_date) : '-' }}
      </template>
      <template #cell(challan_number)="data">
        {{ data.item.challan_number ? (data.item.challan_number) : '-' }}
      </template>
      <template #cell(is_buyer_return_info_unavailable)="data">
        <feather-icon
          v-if="data.item.is_buyer_return_info_unavailable"
          icon="XCircleIcon"
          color="red"
        />
        <feather-icon
          v-else
          icon="CheckCircleIcon"
          color="green"
        />

      </template>
      <template #cell(is_return_received)="data">
        <status-icon :value="data.item.is_return_received" />
      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />

    <add-buyer-return
      v-if="showAddForm"
      @modal-closed="showAddForm = false"
      @item-added="addItemHandler"
    />
    <buyer-return-details
      v-if="openItemId"
      :item-id="openItemId"
      @modal-closed="openItemId=null"
      @onUpdate="fetchItems"
    />
  </div>
</template>
<script>
import {
  BSpinner, BTable, BAlert, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import AddBuyerReturn from '@/components/BuyerReturn/AddBuyerReturn.vue'
import BuyerReturnDetails from '@/components/BuyerReturn/BuyerReturnDetails.vue'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import dateMixin from '@/mixins/dateMixin'
import StatusIcon from '@/components/UI/StatusIcon.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BRow,
    BCol,
    BButton,
    BAlert,
    AddBuyerReturn,
    BuyerReturnDetails,
    DetailedPagination,
    StatusIcon,
  },
  mixins: [dateMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      showAddForm: false,
      currentPage: 1,
      perPage: 50,
      sortBy: 'received_date',
      results: [],
      totalItems: 0,
      sortDesc: true,
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        {
          key: 'is_buyer_return_info_unavailable', label: 'Buyer Return Available', sortable: false, class: 'text-center',
        },
        { key: 'challan_number', label: 'Challan Number', sortable: true },
        { key: 'received_date', label: 'Received Date', sortable: true },
        { key: 'buyer', label: 'Buyer', sortable: true },
        { key: 'brought_by', label: 'Brought By', sortable: true },
        { key: 'recieved_by', label: 'Recieved By', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        { key: 'created_by__fullname', label: 'Created By', sortable: true },
        { key: 'total_items', label: 'Total Items', sortable: true },
        { key: 'returned_date', label: 'Returned Date', sortable: true },
        { key: 'returned_to', label: 'Returned To', sortable: true },
        { key: 'is_return_received', label: 'Return Received', sortable: false },
        { key: 'actions', label: 'Actions' },
      ],
      openItemId: null,

    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      this.loading = true
      axios.post('/returns/filtered_buyer_returns', {
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        filters: { ...this.filters },
      }).then(res => {
        this.results = res.data.results
        this.totalItems = res.data.count
        this.loadingError = null
        this.loading = false
      }).catch(error => {
        this.loadingError = error?.response?.data?.error || 'Something went wrong'
        this.loading = false
      })
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    displayDetails(itemId) {
      this.openItemId = itemId
    },
    addItemHandler() {
      this.showAddForm = false
      this.fetchItems()
    },
  },
}
</script>
