<template>
  <b-card title="Buyer Returns">
    <buyer-return-filters @filtersUpdated="filtersChangeHandler" />
    <buyer-return-list :filters="filters" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import BuyerReturnFilters from '@/components/BuyerReturn/BuyerReturnFilters.vue'
import BuyerReturnList from '@/components/BuyerReturn/BuyerReturnList.vue'

export default {
  components: {
    BCard,
    BuyerReturnFilters,
    BuyerReturnList,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },

}
</script>
