<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="outline-primary"
        @click="isFormVisible = true"
      >
        Add VDPL Challan
      </b-button>
    </div>
    <b-form v-if="isFormVisible">
      <b-card-title>
        {{ vdplForm.id ? 'Update' : 'Add' }} VDPL Challan
      </b-card-title>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Date"
          >
            <FlatPicker v-model="vdplForm.date" />
            <p
              v-if="errorMessage && errorMessage.date"
              class="error-message"
            >
              {{ errorMessage.date[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Challan Number"
          >
            <b-form-input
              v-model="vdplForm.vdpl_challan_number"
              placeholder="Challan Number"
            />
            <p
              v-if="errorMessage && errorMessage.vdpl_challan_number"
              class="error-message"
            >
              {{ errorMessage.vdpl_challan_number[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Total Items"
          >
            <b-form-input
              v-model="vdplForm.total_items"
              placeholder="Total Items"
            />
            <p
              v-if="errorMessage && errorMessage.total_items"
              class="error-message"
            >
              {{ errorMessage.total_items[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Challan File"
          >
            <div
              v-if="(typeof vdplForm.vdpl_challan_file) === 'string'"
            >
              <a
                :href="vdplForm.vdpl_challan_file"
                target="_blank"
              >
                <span>View File</span>
              </a>

              <b-button
                variant="dark-link"
                class="p-0"
                @click="vdplForm.vdpl_challan_file = null"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="16"
                  color="red"
                />
              </b-button>
            </div>
            <b-form-file
              v-else
              v-model="vdplForm.vdpl_challan_file"
              accept=".pdf"
              class="w-100"
            />
            <p
              v-if="errorMessage && errorMessage.vdpl_challan_file"
              class="error-message"
            >
              {{ errorMessage.vdpl_challan_file[0] }}
            </p>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-button
            :disabled="submitting"
            @click="isFormVisible = false"
          >
            Cancel
          </b-button>
          <b-button
            class="ml-1"
            variant="primary"
            :disabled="submitting"
            @click="onSubmit"
          >
            Submit
            <b-spinner
              v-if="submitting"
              small
              label="Small Spinner"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>
    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>
    <div v-if="!isFormVisible && !loading && !loadingError">
      <b-table
        caption="VDPL Challan"
        caption-top
        :items="listingData"
        :fields="tableFields"
        responsive="sm"
        striped
        hover
        no-local-sorting
        class="compact-table"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="sortingChanged"
      >
        <template #cell(vdpl_challan_file)="data">
          <a
            v-if="data.item.vdpl_challan_file"
            :href="data.item.vdpl_challan_file"
            target="_blank"
          >
            <span>View File</span>
          </a>
          <span v-else>-</span>
        </template>
        <template #cell(action)="data">
          <b-button
            variant="dark-link"
            class="p-0"
            @click="onEdit(data.item)"
          >
            <feather-icon
              icon="Edit2Icon"
              size="16"
            />
          </b-button>
        </template>
      </b-table>
      <detailed-pagination
        v-if="!loading && !loadingError"
        :total-items="totalItems"
        :per-page="perPage"
        :current-page="currentPage"
        @page-changed="(page) => {currentPage = page}"
      />
    </div>
  </div>
</template>

<script>
import {
  BCardTitle,
  BButton,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BTable,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import axios from 'axios'
import FlatPicker from '../UI/FlatPicker.vue'
import DetailedPagination from '../UI/DetailedPagination.vue'

export default {
  components: {
    BCardTitle,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    FlatPicker,
    BFormInput,
    BFormFile,
    BTable,
    BSpinner,
    DetailedPagination,
    BAlert,
  },
  props: {
    buyerReturnId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isFormVisible: false,
      vdplForm: {
        vdpl_challan_number: '',
        vdpl_challan_file: null,
        buyer_return: this.buyerReturnId,
        date: null,
      },
      errorMessage: null,
      listingData: [],
      tableFields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'vdpl_challan_number', label: 'Challan Number', sortable: true },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'total_items', label: 'Total Items', sortable: true },
        { key: 'vdpl_challan_file', label: 'Challan File' },
        { key: 'action', label: 'Action' },
      ],
      sortBy: 'vdpl_challan_number',
      sortDesc: true,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      loading: false,
      loadingError: null,
      submitting: false,

    }
  },
  watch: {
    isFormVisible(newVal) {
      if (!newVal) {
        this.vdplForm = {
          vdpl_challan_number: '',
          vdpl_challan_file: null,
          buyer_return: this.buyerReturnId,
          date: null,
        }
        this.errorMessage = null
      }
    },
  },
  mounted() {
    this.fetchInitialData()
  },
  methods: {
    async fetchInitialData() {
      try {
        this.loading = true
        const response = await axios.get('/returns/vdpl_challan', {
          params: {
            sort_by: this.sortBy,
            sort_desc: this.sortDesc,
            buyer_return_id: this.$props.buyerReturnId,
          },
        })
        const { data } = response
        this.listingData = data.results
        this.totalItems = data.count
        this.loadingError = null
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Something went wrong'
      } finally {
        this.loading = false
      }
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchInitialData()
    },
    async onSubmit() {
      try {
        this.submitting = true
        const formData = new FormData()
        Object.keys(this.vdplForm).forEach(key => {
          if (key === 'vdpl_challan_file' && typeof this.vdplForm[key] === 'string') {
            return
          }
          formData.append(key, this.vdplForm[key])
        })

        if (this.vdplForm.id) {
          await axios.patch(`/returns/vdpl_challan/${this.vdplForm.id}/`, formData)
        } else {
          await axios.post('/returns/vdpl_challan/', formData)
        }
        this.$emit('item-added-or-updated')
        this.fetchInitialData()
        this.isFormVisible = false
      } catch (error) {
        this.errorMessage = error.response.data || 'Something went wrong!'
      } finally {
        this.submitting = false
      }
    },
    onEdit(data) {
      this.vdplForm = data
      this.isFormVisible = true
    },
  },
}
</script>

<style scoped>
.error-message {
  color: red
}
</style>
