<template>
  <b-modal
    v-model="showModal"
    title="Add Buyer Return"
    size="lg"
    class="m-4"
    no-close-on-backdrop
    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-form
      @submit.prevent="submitHandler"
    >
      <b-row>
        <b-col cols="12">
          <b-alert
            variant="danger"
            :show="errorMessage ? true : false"
          >
            <div
              v-if="typeof errorMessage === 'string'"
              class="alert-body"
            >
              <p>
                {{ errorMessage }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.detail"
              class="alert-body"
            >
              <p>
                {{ errorMessage.detail }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.non_field_errors"
              class="alert-body"
            >
              <p>
                {{ errorMessage.non_field_errors[0] }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.message"
              class="alert-body"
            >
              <p>
                {{ errorMessage.message }}
              </p>
            </div>
          </b-alert>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Buyer"
            label-for="buyer"
          >
            <custom-v-select
              id="Buyer"
              v-model="selectedBuyer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="buyerOptions"
              label="text"
              placeholder="Select Buyer"
              :class="errorMessage && errorMessage.buyer && errorMessage.buyer.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.buyer"
              class="error-message"
            >
              {{ errorMessage.buyer[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Brought By"
            label-for="brought-by"
          >
            <custom-v-select
              id="brought-by"
              v-model="selectedBroughtBy"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="salesMansOptions"
              label="text"
              placeholder="Select Brought by"
              :class="errorMessage && errorMessage.brought_by && errorMessage.brought_by.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.brought_by"
              class="error-message"
            >
              {{ errorMessage.brought_by[0] }}
            </p>
          </b-form-group>

        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Recieved By"
            label-for="recieved-by"
          >
            <custom-v-select
              id="recieved-by"
              v-model="selectedRecievedBy"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="salesMansOptions"
              label="text"
              placeholder="Select Receieved By"
              :class="errorMessage && errorMessage.recieved_by && errorMessage.recieved_by.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.recieved_by"
              class="error-message"
            >
              {{ errorMessage.recieved_by[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <label for="received-date">Received Date</label>
          <b-form-datepicker
            id="received-date"
            v-model="receivedDate"
            menu-class="w-100"
            calendar-width="100%"
            :class="errorMessage && errorMessage.received_date && errorMessage.received_date.length > 0 ? 'is-invalid':null"
          />
          <p
            v-if="errorMessage && errorMessage.received_date"
            class="error-message"
          >
            {{ errorMessage.received_date[0] }}
          </p>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Buyer Challan Number"
            label-for="challan-number"
          >
            <b-form-input
              id="challan-number"
              v-model="challanNumber"
              type="text"
              placeholder="Enter Challan Number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Buyer Challan File"
            label-for="challan-file"
          >
            <b-form-file
              id="challan-file"
              v-model="challanFile"
              accept=".pdf"
              :disabled="isBuyerReturnInfoUnAvailable"
              :class="errorMessage && errorMessage.buyer_challan_file && errorMessage.buyer_challan_file.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.buyer_challan_file"
              class="error-message"
            >
              {{ errorMessage.buyer_challan_file[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <label for="challan-date">Buyer Challan Date</label>
          <b-form-datepicker
            id="challan-date"
            v-model="challanDate"
            menu-class="w-100"
            calendar-width="100%"
          />
        </b-col>
        <b-col
          cols="6"
          class="d-flex"
        >
          <b-form-checkbox
            v-model="isBuyerReturnInfoUnAvailable"
          />
          <span>Buyer Return Information Not Available</span>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="6">
          <label for="returned-date">Returned Date</label>
          <b-form-datepicker
            id="returned-date"
            v-model="returnedDate"
            menu-class="w-100"
            calendar-width="100%"
            :class="errorMessage && errorMessage.returned_date && errorMessage.returned_date.length > 0 ? 'is-invalid':null"
          />
          <p
            v-if="errorMessage && errorMessage.returned_date"
            class="error-message"
          >
            {{ errorMessage.returned_date[0] }}
          </p>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Returned To"
            label-for="returned-to"
          >
            <custom-v-select
              id="returned-to"
              v-model="selectedReturnedTo"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="salesMansOptions"
              label="text"
              placeholder="Select Returned to"
              :class="errorMessage && errorMessage.returned_to && errorMessage.returned_to.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.returned_to"
              class="error-message"
            >
              {{ errorMessage.returned_to[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Item Return File"
            label-for="item_return_file"
          >
            <b-form-file
              id="item_return_file"
              v-model="itemReturnFile"
              accept=".pdf"
              :class="errorMessage && errorMessage.item_return_file && errorMessage.item_return_file.length > 0 ? 'is-invalid':null"
            />
            <p
              v-if="errorMessage && errorMessage.item_return_file"
              class="error-message"
            >
              {{ errorMessage.item_return_file[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Item Return File(Received)"
            label-for="item_return_received_file"
          >
            <b-form-file
              id="item_return_received_file"
              v-model="itemReceivedReturnFile"
              accept=".pdf"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          <label for="textarea-default">Remarks</label>
          <b-form-textarea
            id="textarea-default"
            v-model="remarks"
            rows="4"
            :class="errorMessage && errorMessage.remarks && errorMessage.remarks.length > 0 ? 'is-invalid':null"
          />
          <p
            v-if="errorMessage && errorMessage.remarks"
            class="error-message"
          >
            {{ errorMessage.remarks[0] }}
          </p>
        </b-col>
      </b-row>

    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving"
        @click="ok()"
      >
        Create
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>

</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BFormInput, BFormDatepicker, BForm, BSpinner, BAlert, BFormTextarea,
  BFormFile, BFormCheckbox,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    CustomVSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BButton,
    BSpinner,
    BAlert,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,
  },

  data() {
    return {
      showModal: true,
      challanNumber: null,
      challanDate: null,
      receivedDate: null,
      selectedBuyer: null,
      selectedBroughtBy: null,
      selectedRecievedBy: null,
      selectedReturnedTo: null,
      isBuyerReturnInfoUnAvailable: false,
      challanFile: null,
      vdplChallanFile: null,
      itemReturnFile: null,
      itemReceivedReturnFile: null,
      returnedDate: null,
      buyerOptions: [],
      salesMansOptions: [],
      errorMessage: {},
      saving: false,
      remarks: null,

    }
  },
  watch: {
    isBuyerReturnInfoUnAvailable(newVal) {
      if (newVal) {
        this.challanFile = null
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/buyers_options')
        .then(res => {
          this.buyerOptions = res.data.data
        })
        // .catch(error => {
        //   console.error('[failed at fetchOptions]', { error })
        // })

      axios.get('/accounts/salesmans_options')
        .then(res => {
          this.salesMansOptions = res.data.data
        })
        // .catch(error => {
        //   console.error('[failed at fetchOptions]', { error })
        // })
    },
    submitHandler(event) {
      event.preventDefault()
      const formData = new FormData()

      if (this.challanNumber) {
        formData.append('challan_number', this.challanNumber)
      }
      if (this.challanDate) {
        formData.append('challan_date', this.challanDate)
      }
      if (this.selectedBuyer) {
        formData.append('buyer', this.selectedBuyer.id)
      }
      if (this.selectedBroughtBy) {
        formData.append('brought_by', this.selectedBroughtBy.id)
      }
      if (this.selectedRecievedBy) {
        formData.append('recieved_by', this.selectedRecievedBy.id)
      }
      if (this.returnedDate) {
        formData.append('returned_date', this.returnedDate)
      }

      formData.append('is_buyer_return_info_unavailable', this.isBuyerReturnInfoUnAvailable)
      if (!this.isBuyerReturnInfoUnAvailable && this.challanFile) {
        formData.append('buyer_challan_file', this.challanFile)
      }

      if (this.vdplChallanFile) {
        formData.append('vdpl_challan_file', this.vdplChallanFile)
      }

      if (this.selectedReturnedTo) {
        formData.append('returned_to', this.selectedReturnedTo.id)
      }

      if (this.itemReturnFile) {
        formData.append('item_return_file', this.itemReturnFile)
      }
      if (this.remarks) {
        formData.append('remarks', this.remarks)
      }

      if (this.itemReceivedReturnFile) {
        formData.append('item_return_received_file', this.itemReceivedReturnFile)
      }

      if (this.receivedDate) {
        formData.append('received_date', this.receivedDate)
      }

      this.saving = true
      axios.post('/returns/buyer_return/', formData)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message || 'Buyer Return Created',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.$emit('item-added')
          this.saving = false
        }).catch(error => {
          this.errorMessage = error?.response?.data || 'Something went wrong'
          this.saving = false
        })
    },
  },
}

</script>

<style scoped>
.error-message {
  color: red
}
</style>
