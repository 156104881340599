<template>
  <b-modal
    v-model="modalShow"
    size="xl"
    scrollable
    title="Buyer Return Details"
    @hidden="$emit('modal-closed')"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">
      <b-table-simple responsive>
        <b-tbody>
          <b-tr>
            <b-th> Buyer </b-th>
            <b-td> {{ itemDetails.buyer__identifier }}</b-td>

            <b-th> Brought By </b-th>
            <b-td> {{ itemDetails.brought_by__name }}</b-td>
          </b-tr>

          <b-tr>
            <b-th>Received By</b-th>
            <b-td> {{ itemDetails.recieved_by__name }} </b-td>

            <b-th>Received Date</b-th>
            <b-td>
              {{
                itemDetails.received_date &&
                  formatedDate(itemDetails.received_date)
              }}
            </b-td>
          </b-tr>

          <b-tr>
            <b-th>Created By</b-th>
            <b-td> {{ itemDetails.created_by__fullname }} </b-td>
            <b-th>Created At</b-th>
            <b-td> {{ itemDetails.created_at && formatedDate(itemDetails.created_at) }} </b-td>
          </b-tr>
          <b-tr>
            <b-th> Buyer Challan Number </b-th>
            <b-td> {{ itemDetails.challan_number || '-' }}</b-td>
            <b-th> Buyer Challan Date </b-th>
            <b-td>
              {{
                itemDetails.challan_date &&
                  formatedDate(itemDetails.challan_date)
              }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Buyer Challan File</b-th>
            <b-td>
              <a
                v-if="itemDetails.buyer_challan_file"
                :href="itemDetails.buyer_challan_file"
                target="_blank"
              >
                <span>View File</span>
              </a>
            </b-td>
            <b-th> Buyer Return Received </b-th>
            <b-td>
              <status-icon
                v-if="itemDetails.is_return_received"
                :value="itemDetails.is_return_received"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>Total Items</b-th>
            <b-td>
              {{ itemDetails.total_items }}
            </b-td>
            <b-th>VDPL Challan File (Old)</b-th>
            <b-td>
              <a
                v-if="itemDetails.vdpl_challan_file"
                :href="itemDetails.vdpl_challan_file"
                target="_blank"
              >
                <span>View File</span>
              </a>
              <span v-else>-</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-alert
        variant="danger"
        :show="errorMessage ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </b-alert>
      <b-table-simple
        class="mt-2"
        bordered
      >
        <b-tbody>
          <b-tr>
            <b-th>Return Date</b-th>
            <b-td>
              <FlatPicker
                v-model="formData.returnDate"
              />
            </b-td>
            <b-th>Return To</b-th>
            <b-td>
              <b-form-group
                label=""
                label-for="returned-to"
              >
                <custom-v-select
                  id="returned-to"
                  v-model="formData.returnTo"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="returnToOptions"
                  label="text"
                  :reduce="(item) => item.id"
                  placeholder="Select Returned to"
                />
              </b-form-group>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> Return File</b-th>
            <b-td>
              <b-row>
                <b-col
                  cols
                  class="my-auto"
                >
                  <a
                    v-if="(typeof formData.returnFile) === 'string'"
                    :href="itemDetails.item_return_file"
                    target="_blank"
                  >
                    <span>View File</span>
                  </a>
                  <b-form-group
                    v-else
                    class="mb-0"
                  >
                    <b-form-file
                      id="item_return_file"
                      v-model="formData.returnFile"
                      accept=".pdf"
                      class="w-100"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="formData.returnFile"
                  class="text-right"
                  cols="auto"
                >
                  <b-button

                    class="btn-icon"
                    variant="link"
                    @click="() => {
                      formData.returnFile = null
                    }"
                  >
                    <feather-icon
                      color="red"
                      size="15"
                      icon="Trash2Icon"
                      class="ml-1"
                    />
                  </b-button>
                </b-col>
              </b-row>

            </b-td>
            <b-th> Return File (Received)</b-th>
            <b-td>
              <b-row>
                <b-col
                  cols
                  class="my-auto"
                >
                  <a
                    v-if="(typeof formData.returnReceivedFile) === 'string'"
                    :href="itemDetails.item_return_received_file"
                    target="_blank"
                  >
                    <span>View File</span>
                  </a>
                  <b-form-group
                    v-else
                    class="mb-0"
                  >
                    <b-form-file
                      id="item_return_received_file"
                      v-model="formData.returnReceivedFile"
                      accept=".pdf"
                      class="w-100"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="formData.returnReceivedFile"
                  class="text-right"
                  cols="auto"
                >
                  <b-button
                    class="btn-icon"
                    variant="link"
                    @click="
                      () => {
                        formData.returnReceivedFile = null;
                      }
                    "
                  >
                    <feather-icon
                      color="red"
                      size="15"
                      icon="Trash2Icon"
                      class="ml-1"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-td>

          </b-tr>
          <b-tr>
            <b-th> Remarks </b-th>
            <b-td colspan="3">
              <b-form-textarea
                id="textarea-default"
                v-model="formData.remarks"
                rows="3"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-end">
        <b-button
          :disabled="submitting"
          variant="primary"
          class="ml-1"
          @click="onUpdate"
        >
          Update
          <b-spinner
            v-if="submitting"
            small
            label="Small Spinner"
          />
        </b-button>
      </div>
      <hr>
    </div>
    <VDPLForm
      :buyer-return-id="itemId"
      @item-added-or-updated="handleVDPLUpdates"
    />
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          variant="secondary"
          @click="modalShow = false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BAlert,
  BSpinner,
  BTableSimple,
  BTbody,
  BTd,
  BTr,
  BTh,
  BFormGroup,
  BFormFile,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import StatusIcon from '@/components/UI/StatusIcon.vue'
import FlatPicker from '../UI/FlatPicker.vue'
import VDPLForm from './VDPLForm.vue'

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
    BFormGroup,
    BFormFile,
    FlatPicker,
    CustomVSelect,
    BRow,
    BCol,
    VDPLForm,
    StatusIcon,
    BFormTextarea,
  },
  mixins: [dateMixin],
  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      itemDetails: {},
      modalShow: true,
      loadingError: null,
      loading: true,
      showAddForm: false,
      submitting: false,
      returnToOptions: [],
      formData: {
        returnDate: null,
        returnTo: null,
        returnFile: null,
        returnReceivedFile: null,
        remarks: null,
      },
      errorMessage: null,
    }
  },
  created() {
    this.fetchOptions()
    this.fetchDetails()
  },
  methods: {
    handleVDPLUpdates() {
      this.fetchDetails()
      this.$emit('onUpdate')
    },
    async fetchOptions() {
      this.loadingError = null
      this.loading = true

      try {
        const { data } = await axios.get('/accounts/salesmans_options')
        this.returnToOptions = data.data

        this.loadingError = null
        this.loading = false
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Something went wrong'
        this.loading = false
      }
    },
    async fetchDetails() {
      this.loadingError = null
      this.loading = true

      try {
        const res = await axios.get(`/returns/buyer_return/${this.itemId}`)
        this.itemDetails = res.data
        const {
          returned_date,
          returned_to,
          item_return_file,
          item_return_received_file,
          remarks,
        } = this.itemDetails

        this.formData = {
          ...this.formData,
          returnDate: returned_date,
          returnTo: returned_to,
          returnFile: item_return_file,
          returnReceivedFile: item_return_received_file,
          remarks,
        }

        this.loadingError = null
        this.loading = false
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Something went wrong'
        this.loading = false
      }
    },
    onUpdate() {
      this.submitting = true
      this.errorMessage = null
      const {
        returnDate, returnTo, returnFile, returnReceivedFile, remarks,
      } = this.formData

      const payload = new FormData()
      if (returnFile && returnFile !== this.itemDetails.item_return_file) {
        payload.append('item_return_file', returnFile)
      } else if (!returnFile) {
        payload.append('is_del_return_file', 'true')
      }

      if (returnReceivedFile && returnReceivedFile !== this.itemDetails.item_return_received_file) {
        payload.append('item_return_received_file', returnReceivedFile)
      } else if (!returnReceivedFile) {
        payload.append('is_del_return_received_file', 'true')
      }

      if (returnDate !== this.itemDetails.returned_date) {
        payload.append('returned_date', returnDate)
      }
      if (returnTo !== this.itemDetails.returned_to) {
        payload.append('returned_to', returnTo || '')
      }
      if (remarks !== this.itemDetails.remarks) {
        payload.append('remarks', remarks || '')
      }
      axios
        .patch(`returns/buyer_return/${this.itemId}/`, payload)
        .then(res => {
          this.itemDetails = res.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Buyer Return Updated Successfully!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$emit('onUpdate')
        })
        .catch(error => {
          this.errorMessage = error?.response?.data?.non_field_errors?.[0] || 'Something Went wrong!'
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>
